<template>
  <div>
    <label
      :class="[
        optionClass,
        active ? activeClass : 'hover:bg-gray-100 dark:hover:bg-gray-800'
      ]"
    >
      <input
        :id="id"
        ref="input"
        v-model="isChecked"
        :value="value"
        :autofocus="autofocus"
        :readonly="readonly"
        :disabled="disabled"
        :name="name"
        :required="required"
        :class="currentClass"
        type="checkbox"
        @blur="onBlur"
        @focus="onFocus"
        @change="onChange"
      >
      <slot :isChecked="isChecked">
        <div
          v-if="label"
          :class="labelClass"
          :for="id"
        >
          <div>
            {{ label }}
            <span v-if="info" :class="infoClass">
              {{ info }}
            </span>
          </div>
          <div v-html="control" />
        </div>
      </slot>
    </label>
  </div>
</template>

<script>

import attributes from '@//mixins/fields/attributes.js'
import methods from '@//mixins/fields/methods.js'
import classes from '@//mixins/fields/classes.js'

export default {
  mixins: [attributes, methods, classes],
  model: {
    prop: 'model',
    event: 'input'
  },
  props: {
    value: {
      type: [String, Object, Number, Boolean, Array],
      default: true
    },
    label: {
      type: String,
      default: null
    },
    info: {
      type: String,
      default: null
    },
    control: {
      type: String,
      default: null
    },
    uncheckedValue: {
      type: [String, Object, Number, Boolean, Array],
      default: false
    },
    indeterminate: {
      type: [Boolean, String],
      default: false
    },
    model: {
      type: [String, Object, Number, Boolean, Array],
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: null
    },
    optionClass: {
      type: [String, Object, Array],
      default: 'py-1 -mx-2 px-2 mb-1 flex items-center rounded transition duration-200'
    },
    baseClass: {
      type: [String, Object, Array],
      default: 'o-checkbox appearance-none h-4 w-4 border border-gray-300 dark:border-gray-700 rounded checked:bg-blue-600 checked:border-blue-600 flex-shrink-0'
    },
    errorClass: {
      type: [String, Boolean],
      default: 'border border-red-300'
    },
    labelClass: {
      type: [String, Object, Array],
      default: 'w-full ml-2 text-sm flex justify-between items-center'
    },
    activeClass: {
      type: [String, Boolean],
      default: 'bg-blue-100/50 dark:bg-blue-800/20'
    },
    infoClass: {
      type: [String, Boolean],
      default: 'block text-gray-600 text-sm dark:text-gray-300'
    }
  },
  data () {
    return {
      currentValue: this.model
    }
  },
  computed: {
    active () {
      return (!this.isArray && this.isChecked) || (this.isChecked && this.isChecked.includes(this.value))
    },
    isArray () {
      return Array.isArray(this.model)
    },
    isChecked: {
      get () {
        if (this.isArray) {
          return this.model
        } else {
          return this.model === this.value
        }
      },
      set (checked) {
        this.currentValue = checked
      }
    }
  },
  watch: {
    indeterminate: {
      handler (indeterminate) {
        this.$nextTick(() => {
          this.setIndeterminate(indeterminate)
        })
      },
      immediate: true
    },
    checked: {
      handler (checked) {
        this.setChecked(checked)
      },
      immediate: true
    }
  },
  methods: {
    setIndeterminate (indeterminate) {
      if (this.$refs && this.$refs.input) {
        this.$refs.input.indeterminate = indeterminate
        // Emit update event to prop
        this.$emit('update:indeterminate', indeterminate)
      }
    },
    setChecked (checked) {
      if (this.$refs && this.$refs.input) {
        this.$refs.input.checked = !checked
        this.$refs.input.click()
        // Emit update event to prop
        this.$emit('update:checked', checked)
      }
    },
    onChange (e) {
      let currentValue
      let isChecked
      if (Array.isArray(this.isChecked)) {
        currentValue = this.currentValue
        isChecked = this.isChecked.includes(this.value)
      } else {
        currentValue = this.currentValue ? this.value : this.uncheckedValue
        isChecked = !!this.currentValue
      }
      this.$emit('input', currentValue)
      this.$emit('change', currentValue)
      this.$emit('update:indeterminate', false)
      this.$emit('update:checked', isChecked)
    },
    onBlur (e) {
      this.$emit('blur', e)
    },
    onFocus (e) {
      this.$emit('focus', e)
    },
    focus () {
      this.$refs.input.focus()
    },
    blur () {
      this.$refs.input.blur()
    }
  }
}
</script>

<style>
.o-checkbox:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
</style>
